@import '../../styles/variables.scss';

.container {
  width: 100%;
  height: 51.25rem;
  font-family: $font-primary;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
}

.content {
  color: $gray-1;
  color: var(--gray-1);
  margin-left: 17%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 11rem;

  > h1 {
    font-size: 144px;
    font-style: normal;
    font-weight: 400;
    line-height: 173px;
    margin: 0;
  }
  > h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin: 0;
    color: $gray_blue;
    color: var(--gray_blue);
  }

  > a {
    display: flex;
    flex-direction: row;
    width: 5.7rem;
    color: $blue-2;
    color: var(--blue-2);
    margin-top: 3rem;
    justify-content: space-between;
    align-items: center;

    > span {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 17px;
    }
  }
}

.balls {
  position: relative;
  height: 100%;
  width: 50%;

  @mixin ball($height, $width, $color) {
    position: absolute;
    height: $height;
    width: $width;
    $border: 1.5px;
    z-index: 1;

    > div {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      margin: auto;
      background: transparent;
      border: solid $border $color;
      border-radius: 50%;
    }
  }
  .greenBall {
    $color: #6cbe45;
    @include ball(311px, 311px, $color);
    bottom: 150px;
    left: 163px;
  }
  .purpleBall {
    $color: #95c2e8;
    @include ball(558px, 558px, $color);
    bottom: 150px;
    right: -260px;
  }
  .orangeBall {
    $color: #f79a83;
    @include ball(324px, 324px, $color);
    bottom: -50px;
    left: 318px;
  }
}
